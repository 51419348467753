@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.animate-float {
  animation: float infinite;
}
